import { IS_TESTNET } from "./is-testnet";

export const SUBGRAPH_V2_URL = IS_TESTNET
	? "https://curtis-graph.solidity.nextstep.guru/subgraphs/name/sushiswap/v2-ethereum"
	: "https://api.goldsky.com/api/public/project_clslspm3c0knv01wvgfb2fqyq/subgraphs/saru/v2-saru/gn";

export const SUBGRAPH_SARU_URL = IS_TESTNET
	? "https://curtis-graph.solidity.nextstep.guru/subgraphs/name/sushiswap/sushiswap-ethereum"
	: "https://api.goldsky.com/api/public/project_clslspm3c0knv01wvgfb2fqyq/subgraphs/saru/sushiswap-saru/gn";

export const SUBGRAPH_ROUTE_PROCESSOR_URL = IS_TESTNET
	? "https://curtis-graph.solidity.nextstep.guru/subgraphs/name/sushiswap/route-processor-ethereum"
	: "https://api.goldsky.com/api/public/project_clslspm3c0knv01wvgfb2fqyq/subgraphs/saru/rp5-saru/gn";
