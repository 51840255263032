/* eslint-disable */
import * as types from './graphql';



/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n\tquery SaruFactory($factoryAddress: ID!) {\n\t\tuniswapFactory(id: $factoryAddress) {\n\t\t\tid\n\t\t\tpairCount\n\t\t\ttotalVolumeUSD\n\t\t\ttotalVolumeETH\n\t\t\tuntrackedVolumeUSD\n\t\t\ttotalLiquidityUSD\n\t\t\ttotalLiquidityETH\n\t\t\ttxCount\n\t\t}\n\t\tuniswapDayDatas(first: 1, orderBy: date, orderDirection: desc) {\n\t\t\tid\n\t\t\tdailyVolumeUSD\n\t\t\tdailyVolumeETH\n\t\t\tdailyVolumeUntracked\n\t\t}\n\t}\n": types.SaruFactoryDocument,
    "\n\tquery GetAllPairs(\n\t\t$first: Int = 1000\n\t\t$skip: Int = 0\n\t\t$block: Block_height\n\t\t$orderBy: Pair_orderBy\n\t\t$orderDirection: OrderDirection\n\t\t$where: Pair_filter\n\t) {\n\t\tpairs(\n\t\t\tfirst: $first\n\t\t\tskip: $skip\n\t\t\tblock: $block\n\t\t\torderBy: $orderBy\n\t\t\torderDirection: $orderDirection\n\t\t\twhere: $where\n\t\t) {\n\t\t\tid\n\t\t\ttoken0 {\n\t\t\t\tname\n\t\t\t\tid\n\t\t\t\tdecimals\n\t\t\t\tsymbol\n\t\t\t}\n\t\t\ttoken1 {\n\t\t\t\tname\n\t\t\t\tid\n\t\t\t\tdecimals\n\t\t\t\tsymbol\n\t\t\t}\n\t\t\tcreatedAtBlockNumber\n\t\t\tcreatedAtTimestamp\n\t\t\treserve0\n\t\t\treserve1\n\t\t\ttotalSupply\n\t\t\treserveUSD\n\t\t\treserveETH\n\t\t\ttoken0Price\n\t\t\ttoken1Price\n\t\t\tvolumeUSD\n\t\t\ttxCount\n\t\t}\n\t}\n": types.GetAllPairsDocument,
    "\n\tquery GetPoolById($pairAddress: Bytes!) {\n\t\tpairDayData: pairDayDatas(\n\t\t\tfirst: 1\n\t\t\torderBy: date\n\t\t\torderDirection: desc\n\t\t\twhere: { pairAddress: $pairAddress }\n\t\t) {\n\t\t\tid\n\t\t\tdailyVolumeUSD\n\t\t\ttoken0 {\n\t\t\t\tid\n\t\t\t\tsymbol\n\t\t\t\tname\n\t\t\t\tdecimals\n\t\t\t}\n\t\t\ttoken1 {\n\t\t\t\tid\n\t\t\t\tsymbol\n\t\t\t\tname\n\t\t\t\tdecimals\n\t\t\t}\n\t\t\treserveUSD\n\t\t\treserve0\n\t\t\treserve1\n\t\t\tdailyVolumeToken0\n\t\t\tdailyVolumeToken1\n\t\t\ttotalSupply\n\t\t}\n\t\tpairHourData: pairHourDatas(\n\t\t\tfirst: 1\n\t\t\torderBy: hourStartUnix\n\t\t\torderDirection: desc\n\t\t\twhere: { pair_: { id: $pairAddress } }\n\t\t) {\n\t\t\tid\n\t\t\thourlyVolumeToken0\n\t\t\thourlyVolumeToken1\n\t\t\treserveUSD\n\t\t\thourlyVolumeUSD\n\t\t\treserve0\n\t\t\treserve1\n\t\t\ttotalSupply\n\t\t}\n\t}\n": types.GetPoolByIdDocument,
    "\n\tquery PairLiquidityChartData($pairId: String, $pairAddress: Bytes) {\n\t\t# Fetch daily volume data\n\t\tdailyData: pairHourDatas(\n\t\t\tfirst: 24\n\t\t\twhere: { pair: $pairId }\n\t\t\torderBy: hourStartUnix\n\t\t\torderDirection: desc\n\t\t) {\n\t\t\tdate: hourStartUnix\n\t\t\tliquidity: reserveUSD\n\t\t}\n\t\t# Fetch weekly volume data\n\t\tweeklyData: pairDayDatas(\n\t\t\tfirst: 7\n\t\t\twhere: { pairAddress: $pairAddress }\n\t\t\torderBy: date\n\t\t\torderDirection: desc\n\t\t) {\n\t\t\tdate\n\t\t\tliquidity: reserveUSD\n\t\t}\n\t\t# Fetch monthly volume data\n\t\tmonthlyData: pairDayDatas(\n\t\t\tfirst: 31\n\t\t\twhere: { pairAddress: $pairAddress }\n\t\t\torderBy: date\n\t\t\torderDirection: desc\n\t\t) {\n\t\t\tdate\n\t\t\tliquidity: reserveUSD\n\t\t}\n\t\t# Fetch yearly volume data\n\t\tyearlyData: pairDayDatas(\n\t\t\tfirst: 365\n\t\t\twhere: { pairAddress: $pairAddress }\n\t\t\torderBy: date\n\t\t\torderDirection: desc\n\t\t) {\n\t\t\tdate\n\t\t\tliquidity: reserveUSD\n\t\t}\n\t\t# Fetch all available data\n\t\tallData: pairDayDatas(where: { pairAddress: $pairAddress }, orderBy: date, orderDirection: desc) {\n\t\t\tdate\n\t\t\tliquidity: reserveUSD\n\t\t}\n\t}\n": types.PairLiquidityChartDataDocument,
    "\n\tquery PairVolumeChartData($pairId: String, $pairAddress: Bytes) {\n\t\t# Fetch daily volume data\n\t\tdailyData: pairHourDatas(\n\t\t\tfirst: 24\n\t\t\twhere: { pair: $pairId }\n\t\t\torderBy: hourStartUnix\n\t\t\torderDirection: desc\n\t\t) {\n\t\t\tdate: hourStartUnix\n\t\t\tvolumeUSD: hourlyVolumeUSD\n\t\t}\n\t\t# Fetch weekly volume data\n\t\tweeklyData: pairDayDatas(\n\t\t\tfirst: 7\n\t\t\twhere: { pairAddress: $pairAddress }\n\t\t\torderBy: date\n\t\t\torderDirection: desc\n\t\t) {\n\t\t\tdate\n\t\t\tvolumeUSD: dailyVolumeUSD\n\t\t}\n\t\t# Fetch monthly volume data\n\t\tmonthlyData: pairDayDatas(\n\t\t\tfirst: 31\n\t\t\twhere: { pairAddress: $pairAddress }\n\t\t\torderBy: date\n\t\t\torderDirection: desc\n\t\t) {\n\t\t\tdate\n\t\t\tvolumeUSD: dailyVolumeUSD\n\t\t}\n\t\t# Fetch yearly volume data\n\t\tyearlyData: pairDayDatas(\n\t\t\tfirst: 365\n\t\t\twhere: { pairAddress: $pairAddress }\n\t\t\torderBy: date\n\t\t\torderDirection: desc\n\t\t) {\n\t\t\tdate\n\t\t\tvolumeUSD: dailyVolumeUSD\n\t\t}\n\t\t# Fetch all available data\n\t\tallData: pairDayDatas(where: { pairAddress: $pairAddress }, orderBy: date, orderDirection: desc) {\n\t\t\tdate\n\t\t\tvolumeUSD: dailyVolumeUSD\n\t\t}\n\t}\n": types.PairVolumeChartDataDocument,
    "\n\tquery GetToken($tokenAddress: String!) {\n\t\ttokenDayDatas(first: 1, orderBy: date, orderDirection: desc, where: { token: $tokenAddress }) {\n\t\t\tpriceUSD\n\t\t\ttotalLiquidityUSD\n\t\t\ttotalLiquidityETH\n\t\t\ttoken {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tsymbol\n\t\t\t\tdecimals\n\t\t\t}\n\t\t}\n\t}\n": types.GetTokenDocument,
    "\n\tquery GetTokens($tokenAddresses: [String!]!) {\n\t\ttokenDayDatas(where: { token_in: $tokenAddresses }) {\n\t\t\ttoken {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tsymbol\n\t\t\t\tdecimals\n\t\t\t}\n\t\t}\n\t}\n": types.GetTokensDocument,
    "\n\tquery Mints($walletAddress: Bytes!, $first: Int = 1000, $skip: Int = 0) {\n\t\tmints(\n\t\t\tfirst: $first\n\t\t\tskip: $skip\n\t\t\torderBy: timestamp\n\t\t\torderDirection: desc\n\t\t\twhere: { to: $walletAddress }\n\t\t) {\n\t\t\tid\n\t\t\tlogIndex\n\t\t\tamountUSD\n\t\t\tamount0\n\t\t\tamount1\n\t\t\tliquidity\n\t\t\tsender\n\t\t\tto\n\t\t\tpair {\n\t\t\t\tid\n\t\t\t\ttoken0 {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tsymbol\n\t\t\t\t\tdecimals\n\t\t\t\t}\n\t\t\t\ttoken1 {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tsymbol\n\t\t\t\t\tdecimals\n\t\t\t\t}\n\t\t\t}\n\t\t\ttransaction {\n\t\t\t\ttimestamp\n\t\t\t\tblockNumber\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n": types.MintsDocument,
    "\n\tquery Burns($walletAddress: Bytes!, $first: Int = 1000, $skip: Int = 0) {\n\t\tburns(\n\t\t\tfirst: $first\n\t\t\tskip: $skip\n\t\t\torderBy: timestamp\n\t\t\torderDirection: desc\n\t\t\twhere: { sender: $walletAddress }\n\t\t) {\n\t\t\tid\n\t\t\tlogIndex\n\t\t\tamountUSD\n\t\t\tamount0\n\t\t\tamount1\n\t\t\tliquidity\n\t\t\tsender\n\t\t\tto\n\t\t\tpair {\n\t\t\t\tid\n\t\t\t\ttoken0 {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tsymbol\n\t\t\t\t\tdecimals\n\t\t\t\t}\n\t\t\t\ttoken1 {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tsymbol\n\t\t\t\t\tdecimals\n\t\t\t\t}\n\t\t\t}\n\t\t\ttransaction {\n\t\t\t\ttimestamp\n\t\t\t\tblockNumber\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n": types.BurnsDocument,
    "\n\tquery Swaps($walletAddress: Bytes!, $first: Int = 1000, $skip: Int = 0) {\n\t\tswaps(\n\t\t\tfirst: $first\n\t\t\tskip: $skip\n\t\t\torderBy: timestamp\n\t\t\torderDirection: desc\n\t\t\twhere: { or: [{ from: $walletAddress }, { to: $walletAddress }] }\n\t\t) {\n\t\t\tid\n\t\t\tlogIndex\n\t\t\tamountUSD\n\t\t\tamount0In\n\t\t\tamount1In\n\t\t\tamount0Out\n\t\t\tamount1Out\n\t\t\tsender\n\t\t\tto\n\t\t\tpair {\n\t\t\t\tid\n\t\t\t\ttoken0 {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tsymbol\n\t\t\t\t\tdecimals\n\t\t\t\t}\n\t\t\t\ttoken1 {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tsymbol\n\t\t\t\t\tdecimals\n\t\t\t\t}\n\t\t\t}\n\t\t\ttransaction {\n\t\t\t\ttimestamp\n\t\t\t\tblockNumber\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n": types.SwapsDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery SaruFactory($factoryAddress: ID!) {\n\t\tuniswapFactory(id: $factoryAddress) {\n\t\t\tid\n\t\t\tpairCount\n\t\t\ttotalVolumeUSD\n\t\t\ttotalVolumeETH\n\t\t\tuntrackedVolumeUSD\n\t\t\ttotalLiquidityUSD\n\t\t\ttotalLiquidityETH\n\t\t\ttxCount\n\t\t}\n\t\tuniswapDayDatas(first: 1, orderBy: date, orderDirection: desc) {\n\t\t\tid\n\t\t\tdailyVolumeUSD\n\t\t\tdailyVolumeETH\n\t\t\tdailyVolumeUntracked\n\t\t}\n\t}\n"): typeof import('./graphql').SaruFactoryDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery GetAllPairs(\n\t\t$first: Int = 1000\n\t\t$skip: Int = 0\n\t\t$block: Block_height\n\t\t$orderBy: Pair_orderBy\n\t\t$orderDirection: OrderDirection\n\t\t$where: Pair_filter\n\t) {\n\t\tpairs(\n\t\t\tfirst: $first\n\t\t\tskip: $skip\n\t\t\tblock: $block\n\t\t\torderBy: $orderBy\n\t\t\torderDirection: $orderDirection\n\t\t\twhere: $where\n\t\t) {\n\t\t\tid\n\t\t\ttoken0 {\n\t\t\t\tname\n\t\t\t\tid\n\t\t\t\tdecimals\n\t\t\t\tsymbol\n\t\t\t}\n\t\t\ttoken1 {\n\t\t\t\tname\n\t\t\t\tid\n\t\t\t\tdecimals\n\t\t\t\tsymbol\n\t\t\t}\n\t\t\tcreatedAtBlockNumber\n\t\t\tcreatedAtTimestamp\n\t\t\treserve0\n\t\t\treserve1\n\t\t\ttotalSupply\n\t\t\treserveUSD\n\t\t\treserveETH\n\t\t\ttoken0Price\n\t\t\ttoken1Price\n\t\t\tvolumeUSD\n\t\t\ttxCount\n\t\t}\n\t}\n"): typeof import('./graphql').GetAllPairsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery GetPoolById($pairAddress: Bytes!) {\n\t\tpairDayData: pairDayDatas(\n\t\t\tfirst: 1\n\t\t\torderBy: date\n\t\t\torderDirection: desc\n\t\t\twhere: { pairAddress: $pairAddress }\n\t\t) {\n\t\t\tid\n\t\t\tdailyVolumeUSD\n\t\t\ttoken0 {\n\t\t\t\tid\n\t\t\t\tsymbol\n\t\t\t\tname\n\t\t\t\tdecimals\n\t\t\t}\n\t\t\ttoken1 {\n\t\t\t\tid\n\t\t\t\tsymbol\n\t\t\t\tname\n\t\t\t\tdecimals\n\t\t\t}\n\t\t\treserveUSD\n\t\t\treserve0\n\t\t\treserve1\n\t\t\tdailyVolumeToken0\n\t\t\tdailyVolumeToken1\n\t\t\ttotalSupply\n\t\t}\n\t\tpairHourData: pairHourDatas(\n\t\t\tfirst: 1\n\t\t\torderBy: hourStartUnix\n\t\t\torderDirection: desc\n\t\t\twhere: { pair_: { id: $pairAddress } }\n\t\t) {\n\t\t\tid\n\t\t\thourlyVolumeToken0\n\t\t\thourlyVolumeToken1\n\t\t\treserveUSD\n\t\t\thourlyVolumeUSD\n\t\t\treserve0\n\t\t\treserve1\n\t\t\ttotalSupply\n\t\t}\n\t}\n"): typeof import('./graphql').GetPoolByIdDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery PairLiquidityChartData($pairId: String, $pairAddress: Bytes) {\n\t\t# Fetch daily volume data\n\t\tdailyData: pairHourDatas(\n\t\t\tfirst: 24\n\t\t\twhere: { pair: $pairId }\n\t\t\torderBy: hourStartUnix\n\t\t\torderDirection: desc\n\t\t) {\n\t\t\tdate: hourStartUnix\n\t\t\tliquidity: reserveUSD\n\t\t}\n\t\t# Fetch weekly volume data\n\t\tweeklyData: pairDayDatas(\n\t\t\tfirst: 7\n\t\t\twhere: { pairAddress: $pairAddress }\n\t\t\torderBy: date\n\t\t\torderDirection: desc\n\t\t) {\n\t\t\tdate\n\t\t\tliquidity: reserveUSD\n\t\t}\n\t\t# Fetch monthly volume data\n\t\tmonthlyData: pairDayDatas(\n\t\t\tfirst: 31\n\t\t\twhere: { pairAddress: $pairAddress }\n\t\t\torderBy: date\n\t\t\torderDirection: desc\n\t\t) {\n\t\t\tdate\n\t\t\tliquidity: reserveUSD\n\t\t}\n\t\t# Fetch yearly volume data\n\t\tyearlyData: pairDayDatas(\n\t\t\tfirst: 365\n\t\t\twhere: { pairAddress: $pairAddress }\n\t\t\torderBy: date\n\t\t\torderDirection: desc\n\t\t) {\n\t\t\tdate\n\t\t\tliquidity: reserveUSD\n\t\t}\n\t\t# Fetch all available data\n\t\tallData: pairDayDatas(where: { pairAddress: $pairAddress }, orderBy: date, orderDirection: desc) {\n\t\t\tdate\n\t\t\tliquidity: reserveUSD\n\t\t}\n\t}\n"): typeof import('./graphql').PairLiquidityChartDataDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery PairVolumeChartData($pairId: String, $pairAddress: Bytes) {\n\t\t# Fetch daily volume data\n\t\tdailyData: pairHourDatas(\n\t\t\tfirst: 24\n\t\t\twhere: { pair: $pairId }\n\t\t\torderBy: hourStartUnix\n\t\t\torderDirection: desc\n\t\t) {\n\t\t\tdate: hourStartUnix\n\t\t\tvolumeUSD: hourlyVolumeUSD\n\t\t}\n\t\t# Fetch weekly volume data\n\t\tweeklyData: pairDayDatas(\n\t\t\tfirst: 7\n\t\t\twhere: { pairAddress: $pairAddress }\n\t\t\torderBy: date\n\t\t\torderDirection: desc\n\t\t) {\n\t\t\tdate\n\t\t\tvolumeUSD: dailyVolumeUSD\n\t\t}\n\t\t# Fetch monthly volume data\n\t\tmonthlyData: pairDayDatas(\n\t\t\tfirst: 31\n\t\t\twhere: { pairAddress: $pairAddress }\n\t\t\torderBy: date\n\t\t\torderDirection: desc\n\t\t) {\n\t\t\tdate\n\t\t\tvolumeUSD: dailyVolumeUSD\n\t\t}\n\t\t# Fetch yearly volume data\n\t\tyearlyData: pairDayDatas(\n\t\t\tfirst: 365\n\t\t\twhere: { pairAddress: $pairAddress }\n\t\t\torderBy: date\n\t\t\torderDirection: desc\n\t\t) {\n\t\t\tdate\n\t\t\tvolumeUSD: dailyVolumeUSD\n\t\t}\n\t\t# Fetch all available data\n\t\tallData: pairDayDatas(where: { pairAddress: $pairAddress }, orderBy: date, orderDirection: desc) {\n\t\t\tdate\n\t\t\tvolumeUSD: dailyVolumeUSD\n\t\t}\n\t}\n"): typeof import('./graphql').PairVolumeChartDataDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery GetToken($tokenAddress: String!) {\n\t\ttokenDayDatas(first: 1, orderBy: date, orderDirection: desc, where: { token: $tokenAddress }) {\n\t\t\tpriceUSD\n\t\t\ttotalLiquidityUSD\n\t\t\ttotalLiquidityETH\n\t\t\ttoken {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tsymbol\n\t\t\t\tdecimals\n\t\t\t}\n\t\t}\n\t}\n"): typeof import('./graphql').GetTokenDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery GetTokens($tokenAddresses: [String!]!) {\n\t\ttokenDayDatas(where: { token_in: $tokenAddresses }) {\n\t\t\ttoken {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tsymbol\n\t\t\t\tdecimals\n\t\t\t}\n\t\t}\n\t}\n"): typeof import('./graphql').GetTokensDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery Mints($walletAddress: Bytes!, $first: Int = 1000, $skip: Int = 0) {\n\t\tmints(\n\t\t\tfirst: $first\n\t\t\tskip: $skip\n\t\t\torderBy: timestamp\n\t\t\torderDirection: desc\n\t\t\twhere: { to: $walletAddress }\n\t\t) {\n\t\t\tid\n\t\t\tlogIndex\n\t\t\tamountUSD\n\t\t\tamount0\n\t\t\tamount1\n\t\t\tliquidity\n\t\t\tsender\n\t\t\tto\n\t\t\tpair {\n\t\t\t\tid\n\t\t\t\ttoken0 {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tsymbol\n\t\t\t\t\tdecimals\n\t\t\t\t}\n\t\t\t\ttoken1 {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tsymbol\n\t\t\t\t\tdecimals\n\t\t\t\t}\n\t\t\t}\n\t\t\ttransaction {\n\t\t\t\ttimestamp\n\t\t\t\tblockNumber\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n"): typeof import('./graphql').MintsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery Burns($walletAddress: Bytes!, $first: Int = 1000, $skip: Int = 0) {\n\t\tburns(\n\t\t\tfirst: $first\n\t\t\tskip: $skip\n\t\t\torderBy: timestamp\n\t\t\torderDirection: desc\n\t\t\twhere: { sender: $walletAddress }\n\t\t) {\n\t\t\tid\n\t\t\tlogIndex\n\t\t\tamountUSD\n\t\t\tamount0\n\t\t\tamount1\n\t\t\tliquidity\n\t\t\tsender\n\t\t\tto\n\t\t\tpair {\n\t\t\t\tid\n\t\t\t\ttoken0 {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tsymbol\n\t\t\t\t\tdecimals\n\t\t\t\t}\n\t\t\t\ttoken1 {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tsymbol\n\t\t\t\t\tdecimals\n\t\t\t\t}\n\t\t\t}\n\t\t\ttransaction {\n\t\t\t\ttimestamp\n\t\t\t\tblockNumber\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n"): typeof import('./graphql').BurnsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery Swaps($walletAddress: Bytes!, $first: Int = 1000, $skip: Int = 0) {\n\t\tswaps(\n\t\t\tfirst: $first\n\t\t\tskip: $skip\n\t\t\torderBy: timestamp\n\t\t\torderDirection: desc\n\t\t\twhere: { or: [{ from: $walletAddress }, { to: $walletAddress }] }\n\t\t) {\n\t\t\tid\n\t\t\tlogIndex\n\t\t\tamountUSD\n\t\t\tamount0In\n\t\t\tamount1In\n\t\t\tamount0Out\n\t\t\tamount1Out\n\t\t\tsender\n\t\t\tto\n\t\t\tpair {\n\t\t\t\tid\n\t\t\t\ttoken0 {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tsymbol\n\t\t\t\t\tdecimals\n\t\t\t\t}\n\t\t\t\ttoken1 {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tsymbol\n\t\t\t\t\tdecimals\n\t\t\t\t}\n\t\t\t}\n\t\t\ttransaction {\n\t\t\t\ttimestamp\n\t\t\t\tblockNumber\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n"): typeof import('./graphql').SwapsDocument;


export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}
