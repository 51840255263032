import { SUBGRAPH_ROUTE_PROCESSOR_URL, SUBGRAPH_SARU_URL, SUBGRAPH_V2_URL } from "@/constants/subgraph-url";
import type { TypedDocumentString } from "./v2-ethereum/graphql";
import type { TypedDocumentString as TypedDocumentStringSaru } from "./saru-swap/graphql";
import type { TypedDocumentString as TypedDocumentStringRouteProcessor } from "./route-processor/graphql";
import { ExecutionResult } from "graphql";

const URLS = {
	v2: SUBGRAPH_V2_URL,
	"saru-swap": SUBGRAPH_SARU_URL,
	"route-processor": SUBGRAPH_ROUTE_PROCESSOR_URL,
};

export async function execute<TResult, TVariables>(
	subgraphUrl: "v2" | "saru-swap" | "route-processor",
	query:
		| TypedDocumentString<TResult, TVariables>
		| TypedDocumentStringSaru<TResult, TVariables>
		| TypedDocumentStringRouteProcessor<TResult, TVariables>,
	...[variables]: TVariables extends Record<string, never> ? [] : [TVariables]
) {
	const url = URLS[subgraphUrl];

	const response = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/graphql-response+json",
		},
		body: JSON.stringify({
			query: query,
			variables,
		}),
	});

	if (!response.ok) {
		throw new Error("Network response was not ok");
	}

	return response.json() as Promise<ExecutionResult<TResult>>;
}
